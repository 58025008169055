// Required plugins
import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
// Components
import DefaultLayout from "../components/Layouts/DefaultLayout"
import TabbedContent from "../components/tabbed-content/tabbled-content"
import SponsorSlider from "../components/sponsor-slider/sponsor-slider"
import TextImage from "../components/text-image/text-image"
import HomepageHero from "../components/homepage-hero/homepage-hero"
import UpcomingMatch from "../components/upcoming-match/upcoming-match"
import VideoHighlight from "../components/video-highlight/video-highlight"
import AwardAchivementSlider from "../components/award-achivement-slider/award-achivement-slider"
import LatestNewResult from "../components/latest-new-result/latest-new-result"
import TextImageRight from "../components/text-image-right/text-image-right"
import TestimonialSlide from "../components/testimonial-slider/testimonial-slider"


const IndexPage = ({ data }) => {
  
  const homeData = data?.wpPage?.homepage
  // Hero section
  let heroData = homeData?.heroSlider?.slide

  // about section
  const aboutSectionData = homeData?.aboutSection
  const about = {
    heading: aboutSectionData?.heading,
    desc: aboutSectionData?.description,
    image: getImage(aboutSectionData?.aboutImage?.localFile),
  }
  //   Team Intro section
  const teamIntroData = homeData?.teamIntro
  const teamIntro = {
    heading: teamIntroData?.teamIntroHeading,
    desc: teamIntroData?.teamIntroDescription,
    image: getImage(teamIntroData?.teamIntroImage?.localFile),
  }

  // Award slide
  const awardsList = homeData?.awardsSlider.awards
  const awardsBg = homeData?.awardsSlider
  // Sponsor slide
  const sponsorLogoList = homeData?.sponsorBanner.sponsorLogos
  const sponsorBg = homeData?.sponsorBanner
  // Charity Intro
  const charityIntroData = homeData?.charityIntro

  // Upcoming match
  const upComingMatchData = data?.allWpFixture?.nodes
  const upComingMatchImage = homeData?.upcomingFixtures
  
  // Match Reports
  const postData = data.allWpPost?.nodes

  // Fixtures
  const fixtures = data.allWpFixture?.nodes
  
  const matchData = postData.filter(e => e?.categories?.nodes[0].slug === "match-reports")

  //Latest News and Result Background Image Data
  const resultImage = homeData?.latestNewAndResult

  //Testimonial Slide Data
  const testimonialData = homeData?.testimonialSlide

  //Latest videos background image data
  const latestVidData = homeData?.latestVideos

  return (
    <>
      <DefaultLayout data={data}>
      <HomepageHero heroData={heroData} />
      <TextImage
        heading={about.heading}
        description={about.desc}
        image={about.image}
      />
      <TextImageRight
        heading={teamIntro.heading}
        description={teamIntro.desc}
        image={teamIntro.image}
      />

      <UpcomingMatch upComingMatchData={upComingMatchData} upComingMatchImage={upComingMatchImage} />


      {/* <AwardAchivementSlider awardsList={awardsList} bgImage={awardsBg}/> */}

      <LatestNewResult postData={postData} fixtures={fixtures} resultImage={resultImage}/>

      <SponsorSlider sponsorLogoList={sponsorLogoList} bgImage={sponsorBg}/>
      
      {/* <VideoHighlight matchData={matchData} latestVidData={latestVidData}/> */}

      <TabbedContent charityIntroData={charityIntroData} />
      
      {/* <TestimonialSlide testimonialData={testimonialData} /> */}

      </DefaultLayout>
    </>
  )
}

export const query = graphql`
  query ($slug: String!) {
    allWpFixture {
      nodes {
        slug
        title
        uri
        fixtures {
          awayTeam
          homeTeam
          fixtureDate
          stadium
          time
          ctaLink {
            target
            title
            url
          }
          score
          matchLink
          awayTeamLogo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          homeTeamLogo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    allWpPost (limit: 4, sort: {fields: date, order: DESC}) {
      nodes {
        id
        uri
        title
        date(formatString: "DD MMMM, YYYY")
        slug
        customFields {
          date
          score
          time
          title
          matchLink {
            url
          }
          venue
          teamOne
          teamTwo
          logoTeam1 {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          logoTeam2 {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          thumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        excerpt
        categories {
          nodes {
            slug
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData (width: 620, height: 330)
              }
            }
          }
        }
      }
    }
    wpPage(slug: { eq: $slug }) {
      id
      title
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
      }
      homepage {
        upcomingFixtures {
          sectionBgImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        aboutSection {
          description
          heading
          aboutImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 504
                  height: 380
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        latestVideos {
          backgroundImage {
            localFile {
              childImageSharp {
                fluid(fit: COVER, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        latestNewAndResult {
          backgroundImage {
            localFile {
              childImageSharp {
                fluid(fit: COVER, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        teamIntro {
          teamIntroDescription
          teamIntroHeading
          teamIntroImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 500
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        heroSlider {
          slide {
            mainHeading
            leftButtonLink {
              url
              title
              target
            }
            rightButtonLink {
              url
              title
              target
            }
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    width: 1920
                    height: 800
                  )
                }
              }
            }
          }
        }
        sponsorBanner {
          backgroundImage {
            localFile {
              childImageSharp {
                fluid(fit: COVER, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          sponsorLogos {
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
        awardsSlider {
          backgroundImage {
            localFile {
              childImageSharp {
                fluid(fit: COVER, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          awards {
            awardImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    height: 323
                    width: 247
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            titleHeading
          }
        }
        testimonialSlide {
          title
          testimonial {
            name
            personalImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 352, width: 352)
                }
              }
            }
            role
            text
          }
        }
        charityIntro {
          firstTabHeading
          firstTabDescription
          image1 {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 380, width: 380)
              }
            }
          }
          secondTabHeading
          secondTabDescription
          image2 {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 380, width: 380)
              }
            }
          }
          thirdTabHeading
          thirdTabDescription
          image3 {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 380, width: 380)
              }
            }
          }
          fourthTabHeading
          fourthTabDescription
          image4 {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 380, width: 380)
              }
            }
          }
          nameClubMember
          positionInClub
        }
      }
    }
  }
`

export default IndexPage
